import { render, staticRenderFns } from "./help.vue?vue&type=template&id=481baa90"
import script from "./help.vue?vue&type=script&lang=js"
export * from "./help.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/go/src/github.com/photoprism/photoprism/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('481baa90')) {
      api.createRecord('481baa90', component.options)
    } else {
      api.reload('481baa90', component.options)
    }
    module.hot.accept("./help.vue?vue&type=template&id=481baa90", function () {
      api.rerender('481baa90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/help.vue"
export default component.exports