import { render, staticRenderFns } from "./share.vue?vue&type=template&id=1ce27e9e"
import script from "./share.vue?vue&type=script&lang=js"
export * from "./share.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/go/src/github.com/photoprism/photoprism/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ce27e9e')) {
      api.createRecord('1ce27e9e', component.options)
    } else {
      api.reload('1ce27e9e', component.options)
    }
    module.hot.accept("./share.vue?vue&type=template&id=1ce27e9e", function () {
      api.rerender('1ce27e9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dialog/share.vue"
export default component.exports